body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
    scrollbar-width: auto;
    scrollbar-color: #ff8733 #ffffff00;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
    width: 8px;
}

*::-webkit-scrollbar-track {
    background: #ffffff00;
}

*::-webkit-scrollbar-thumb {
    background-color: #ff8733;
    border-radius: 10px;
    border: 3px solid #ffffff00;
}

a {
    color: inherit;
    text-decoration: none;
}

.active {
    color: #ff8733;
}

.notification {
    background-color: #ff8733;
    color: black;
    position: fixed;
    width: 100vw;
    height: 25px;
    text-align: center;
    z-index: 9999;
    transform: translateY(-25px);
    animation: slideDown 10s;
}

@keyframes slideDown {
    0% {
        transform: translateY(-25px);
    }
    10% {
        transform: translateY(0px);
    }
    90% {
        transform: translateY(0px);
    }
    100% {
        transform: translateY(-25px);
    }
}

@-moz-keyframes slideDown {
    0% {
        transform: translateY(-25px);
    }
    10% {
        transform: translateY(0px);
    }
    90% {
        transform: translateY(0px);
    }
    100% {
        transform: translateY(-25px);
    }
}

@-webkit-keyframes slideDown {
    0% {
        transform: translateY(-25px);
    }
    10% {
        transform: translateY(0px);
    }
    90% {
        transform: translateY(0px);
    }
    100% {
        transform: translateY(-25px);
    }
}

@-o-keyframes slideDown {
    0% {
        transform: translateY(-25px);
    }
    10% {
        transform: translateY(0px);
    }
    90% {
        transform: translateY(0px);
    }
    100% {
        transform: translateY(-25px);
    }
}

@-ms-keyframes slideDown {
    0% {
        transform: translateY(-25px);
    }
    10% {
        transform: translateY(0px);
    }
    90% {
        transform: translateY(0px);
    }
    100% {
        transform: translateY(-25px);
    }
}

@import url("react-quill/dist/quill.core.css");
@import url("react-quill/dist/quill.snow.css");

/* .ql-toolbar *,
.ql-stroke {
    color: white !important;
    fill: transparent !important;
    stroke: white !important;
    background-color: transparent !important;
} */

.ql-picker-label,
.ql-stroke {
    color: white !important;
    stroke: white !important;
}

.ql-even.ql-fill {
    fill: white !important;
}

.ql-container {
    height: 350px !important;
}
